import React from 'react'
import Mes from "../img/measure.PNG";
import Surv from "../img/survey.PNG";

import{useState} from 'react';
import Logo from "../img/logo192.png";

export const Home = () => {

  const [report, setReport]= useState( <div className='iframeBox' >
<iframe title="DIP&PAT" className='iframe'
 src="https://app.powerbi.com/view?r=eyJrIjoiN2YyYTYxYzgtZjI0Yi00ZGUyLTg2Y2ItYTBmMmFiZmIzMGFhIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9&pageName=ReportSection9f0949156c34f23659e8" 
 frameborder="0" allowFullScreen="true"></iframe>  
 <a href='https://app.powerbi.com/view?r=eyJrIjoiN2YyYTYxYzgtZjI0Yi00ZGUyLTg2Y2ItYTBmMmFiZmIzMGFhIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9&pageName=ReportSection9f0949156c34f23659e8'>PowerBi Link</a>  </div> );

const [fullname, setFullname]= useState();
const [org, setOrg]= useState();
const [phone, setPhone]= useState();
const [mail, setMail]= useState();
const [mes, setMes]= useState();

const handleName = e => {
  setFullname(e.target.value);
};
const handleOrg = e => {
  setOrg(e.target.value);
};
const handlePhoneNumber = e => {
  setPhone(e.target.value);
};
const handleEmail = e => {
  setMail(e.target.value);
};
const handleMessage = e => {
  setMes(e.target.value);
};
  return (
<>
<header className='header' >
    <img className='Logo' src={Logo} alt=""  />


        <a className='title' href="#reports" >Reports</a>


        <a className='title' href="#contact" >Contact</a>
   

 
      {/* <p className='title'  >Pricing</p> */}
    </header>
  <div className='section cc-store-home-wrap'>
    <div className="intro-header">
      <div className="intro-content ">
        <div className="intro-text">
          <div className="heading-jumbo">
            Interactive Education Reporting
          </div>
          <div className="paragraph-bigger">
            Take your reporting to the next level
          </div>
        </div>
        <a href="#contact" className="button cc-jumbo-button  w-inline-block">
          <div>
            Get Started Today
          </div>
        </a>
        
      </div>
      
    </div>
{/*************************** HERO **************************************/}    
    <div className='container'>

      <div className='motto-wrap'>
        <div className='label cc-light'>
          <div className="label cc-light">
            Take the headeach out of year end reporting
          </div>
          <div className="heading-jumbo-small">
            Turn your data into interactive reports you can present to shareholders and use for strategic planning.
           Reports can be made available privately through direct file-sharing or publicly through web links and embeds.
            We also have options for fully managed solutions where you just send us your data and we host and maintain the reports.
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div className="home-content-wrap">
        <div className="w-layout-grid about-grid">
          <div>
            <div cclassNameass="home-section-wrap">
                <div className="label cc-light">Alberta Education</div>
                <h2 className="section-heading">AEAM Reporting</h2>
                <p className="paragraph-light">
                  See you AEAM data in a whole new light. First see 
                  a overall summary of all your measures. Then a detailed view 
                  of all shareholders.
                </p>  
            </div>
              <a href="#contact" className="button w-inline-block">
                <div>Demo Report </div>
              </a>
          </div>
          <img src={Mes} />
        </div>
        <div className="w-layout-grid about-grid-2">
        <img className='bottom' src={Surv} />
          <div className='top'>
            <div className="home-section-wrap">
              <div className="label cc-light">Alberta Education</div>
              <h2 className="section-heading">Survey Results</h2>
              <p className="paragraph-light">
                The next step after seeing the summerized results is to 
                drill down one step further. View the survey results of each 
                audience type so you can make informed decisions.
              </p>
            </div>
              <a href="#reports" className="button w-inline-block">
                <div>Demo Report</div>
              </a>
          </div>
          
        </div>
      </div>
      <div className="divider"></div>
      
{/*************************** Reports **************************************/}
      <div name='reports' id='reports' className='btn-group'>
      <a  onClick={()=>
        setReport(<div className='iframeBox' >
         <iframe title="DIP&PAT" className='iframe' src="https://app.powerbi.com/view?r=eyJrIjoiN2YyYTYxYzgtZjI0Yi00ZGUyLTg2Y2ItYTBmMmFiZmIzMGFhIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9&pageName=ReportSection9f0949156c34f23659e8" frameborder="0" allowFullScreen="true"></iframe>    
        <a href='https://app.powerbi.com/view?r=eyJrIjoiN2YyYTYxYzgtZjI0Yi00ZGUyLTg2Y2ItYTBmMmFiZmIzMGFhIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9&pageName=ReportSection9f0949156c34f23659e8'>PowerBi Link</a>
        </div>)
        } className="button w-inline-block Hov">
                <div>AEAM</div>
      </a>
      <a onClick={()=>
        setReport(<div className='iframeBox' >
          <iframe title="EnrollmentReport" className='iframe'  src="https://app.powerbi.com/view?r=eyJrIjoiZDdjNGM1OTgtZTA3Ni00MWEyLThkZWMtZTRiNjE2ZmE4OGVkIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9&pageName=7e5c77f6e6573112c421" frameborder="0" allowFullScreen="true"></iframe>
          <a href='https://app.powerbi.com/view?r=eyJrIjoiZDdjNGM1OTgtZTA3Ni00MWEyLThkZWMtZTRiNjE2ZmE4OGVkIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9&pageName=7e5c77f6e6573112c421'>PowerBi Link </a>
        </div>
        )
      } className="button w-inline-block Hov">
                <div>Enrollment</div>
      </a>
      <a onClick={()=>
       setReport(<div className='iframeBox' >
        <iframe title="PATReport" className='iframe'  src="https://app.powerbi.com/view?r=eyJrIjoiZTA5YmM2NjctNzcwMy00OTA2LTk1ZDQtNWIyYTJhMTQyMGVmIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9" frameborder="0" allowFullScreen="true"></iframe>
        <a href='https://app.powerbi.com/view?r=eyJrIjoiZTA5YmM2NjctNzcwMy00OTA2LTk1ZDQtNWIyYTJhMTQyMGVmIiwidCI6IjkyYjQ2NTMxLTAyNzEtNGQ4NC1iMTY2LTU4OTY2NjUzODU2YyJ9'>PowerBi Link </a>
      </div>) 
      } className="button w-inline-block Hov">
          <div>PAT</div>
      </a>
      </div>
      <div  className='report'>

            {report}

      </div>

      <div className="divider"></div>

{/*************************** Contact **************************************/}    

 <div id='contact' class="contact_us_green">
  <div class="responsive-container-block big-container">
    <div class="responsive-container-block container">
      <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-7 wk-ipadp-10 line" id="i69b-2">
        <form  class="form-box">
          <div class="container-block form-wrapper">
            <div class="head-text-box">
              <p class="text-blk contactus-head">
                Contact us
              </p>
              <p class="text-blk contactus-subhead">
              Get started today! Depending on the level of customization,
               reports can be completed in less than a week! AEAM reports can be done at the district and school levels.
               We are also taking on work right now so if you have other data sets you would like to visualize, drop us a line. 
              </p>
            </div>
            <div class="responsive-container-block">
              <div class="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6" id="i10mt-6">
                <p class="text-blk input-title">
                   NAME
                </p>
                <input onChange={handleName} class="input" id="ijowk-6" name="Name"/>
              </div>
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                <p class="text-blk input-title">
                  ORGANIZATION
                </p>
                <input onChange={handleOrg} class="input" id="indfi-4" name="Organization"/>
              </div>
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                <p class="text-blk input-title">
                  EMAIL
                </p>
                <input onChange={handleEmail} class="input" id="ipmgh-6" name="Email"/>
              </div>
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                <p class="text-blk input-title">
                  PHONE NUMBER
                </p>
                <input onChange={handlePhoneNumber} class="input" id="imgis-5" name="PhoneNumber" />
              </div>
              <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i-6">
                <p class="text-blk input-title">
                  Message
                </p>
                <textarea onChange={handleMessage} class="textinput" id="i5vyy-6" placeholder="Message Goes Here..."name='message'></textarea>
              </div>
            </div>
            <a class="btn-wrapper" href={`mailto:contact@nickconsults.ca?subject=${org},${fullname},${mail},${phone}?body=${mes}`}>
            
              <a   class="submit-btn">
                Send
              </a>
            </a>
          </div>
        </form>
      </div>
      <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-5 wk-ipadp-10" id="ifgi">
        <div class="container-box">
          <div class="text-content">
            <p class="text-blk contactus-head">
              Contact us
            </p>
            <p class="text-blk contactus-subhead">
               Get started today! Depending on the level of customization,
               reports can be completed in less than a week! AEAM reports can be done at the district and school levels.
               We are also taking on work right now so if you have other data sets you would like to visualize, drop us a line. 
            </p>
          </div>
          <div class="workik-contact-bigbox">
            <div class="workik-contact-box">
              <div class="phone text-box">
                <img class="contact-svg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET21.jpg"/>
                <p class="contact-text">
                  +1 (780) 201-8642
                </p>
              </div>
              <div class="address text-box">
                <img class="contact-svg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET22.jpg"/>
                <p class="contact-text">
                  contact@nickconsults.ca
                </p>
              </div>
              <div class="mail text-box">
                <img class="contact-svg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET23.jpg"/>
                <p class="contact-text">
                  Edmonton, Alberta, Canada
                </p>
              </div>
            </div>
            {/* <div class="social-media-links">
              <a href="">
                <img class="social-svg" id="is9ym" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-mail.svg"/>
              </a>
              <a href="">
                <img class="social-svg" id="i706n" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-twitter.svg"/>
              </a>
              <a href="">
                <img class="social-svg" id="ib9ve" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg"/>
              </a>
              <a href="">
                <img class="social-svg" id="ie9fx" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-fb.svg"/>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/*************************** Pricing **************************************/}

{/* 
<div className="divider"></div>
<div >
  <h1 class="text-center">Pick the best plan for you</h1>

  <div class="pricing-box-container">
    <div class="pricing-box text-center">
      <h5>Free</h5>
      <p class="price"><sup>$</sup>0<sub>/mo</sub></p>
      <ul class="features-list">
        <li><strong>1</strong> Project</li>
        <li><strong>5</strong> Team Members</li>
        <li><strong>50</strong> Personal Projects</li>
        <li><strong>5,000</strong> Messages</li>
      </ul>
      <button class="btn-primary">Get Started</button>
    </div>

    <div class="pricing-box  text-center">
      <h5>Premium</h5>
      <p class="price"><sup>$</sup>39<sub>/mo</sub></p>
      <ul class="features-list">
        <li><strong>5</strong> Project</li>
        <li><strong>20</strong> Team Members</li>
        <li><strong>100</strong> Personal Projects</li>
        <li><strong>15,000</strong> Messages</li>
      </ul>
      <button class="btn-primary">Get Started</button>
    </div>

    <div class="pricing-box text-center">
      <h5>Platinum</h5>
      <p class="price"><sup>$</sup>89<sub>/mo</sub></p>
      <ul class="features-list">
        <li><strong>25</strong> Project</li>
        <li><strong>50</strong> Team Members</li>
        <li><strong>500</strong> Personal Projects</li>
        <li><strong>50,000</strong> Messages</li>
      </ul>
      <button class="btn-primary">Get Started</button>
    </div>
  </div>


  </div> */}
</div>

</div>
</>
  )
}
